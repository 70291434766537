<template>
  <div class="oci-driver-job-top">
    <h2>
      <b-row class="align-items-center">
        <b-col class="col-4"></b-col>
        <b-col class="col-1">
          <feather-icon
            icon="TruckIcon"
            size="19"
            style="margin-bottom: 7px;"
          />
        </b-col>
        <b-col class="col-1">
          {{ bookingData.job }}
        </b-col>
        <b-col class="col-6"></b-col>
      </b-row>
    </h2>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BImg, BCard, BRow, BCol, } from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
  },
  computed: {
    ...mapGetters({ bookingData: 'app/getCurrentBookingData' }),
  },
}
</script>
